@import '../includes';

// Set default variables here, make sure all exist here before adding to theme
:root {
    // Define variables for different colors first
    --primary: #{$red};
    --primary-5: #{$red-10};
    --primary-30: #{$red-30};
    --primary-60: #{$red-60};
    --primary-80: #{$red-70};

    --secondary: #{$purple};

    --error: #{$system-error};
    --error-10: #{$system-error-10};
    --warning: #{$system-warning};
    --warning-10: #{$system-warning-10};

    // Define variables for locations
    --selection-bg: var(--primary-60);

    --link-color: var(--primary);

    --red-icon-color: var(--primary);

    --button-bg: var(--primary);
    --button-bg-hover: var(--primary-60);
    --button-bg-active: var(--primary-30);
    --button-color: white;

    --header-height: 94px;
    --header-height-lg: 75px;

    --header-bg: var(--primary);
    --header-color: white;
    --header-color-hover: white;
    --header-menu-item-bg: white;
    --header-menu-item-color-hover: var(--primary);
    --header-sub-color: var(--primary);
    --header-sub-color-hover: var(--primary);
    --header-service-bg: white;
    --header-service-color: #{$gray-60};
    --header-service-color-hover: var(--primary);
    --header-service-border: #{$gray-15};

    --mobile-menu-bg: var(--primary);
    --mobile-menu-border: #{rgba(white, .2)};
    --mobile-menu-list-bg: var(--primary-60);
    --mobile-menu-sub-bg: var(--primary-80);

    --submenu-bg: var(--primary-60);
    --submenu-bg-active: var(--primary);
    --submenu-bg-hover: var(--primary);

    --footer-bg: white;
    --footer-color: #{$beige};
    --footer-link-color: var(--link-color);
    --footer-border: #{$gray-20};

    --embed-message-bg: var(--primary);

    --quote-color: var(--primary);

    --checkbox-border: var(--primary);
    --checkbox-bg: var(--primary);

    --radio-border: var(--primary);
    --radio-bg: var(--primary);

    --error-color: var(--error);
    --error-bg: var(--error-10);

    --warning-color: var(--warning);
    --warning-bg: var(--warning-10);

    --tab-color: var(--primary);

    --icon-color: var(--primary);

    --highlight-color: var(--primary);

    --input-select-bg: var(--primary);
    --input-hover-bg: var(--primary);

    --loader-color: var(--primary);

    --promo-bg: var(--secondary);
    --promo-color: white;

    --expandable-border: var(--primary);
    --expandable-toggle-active: var(--primary);
}
