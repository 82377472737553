* {
    box-sizing: border-box;
}

html {
    font-family: $base-font;
    color: $text;
    font-size: 62.5%;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.6rem;
    height: 100%;
    top: 0 !important;

    &.preview {
        background: white;
    }

    &.no-scroll {
        overflow: hidden;
    }

    ::selection {
        color: white;
        background: $selection-bg;
    }

    &>.skiptranslate {
        display: none;
    }

    *[href] {
        @media print {
            &::after {
                content: ' [' attr(href) '] ';
            }
        }
    }
}

.rdw-link-decorator-icon {
    right: 0;
    left: auto;
    animation: fadein 0.25s ease;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    text-transform: none;
}
