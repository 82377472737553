// Custom styling for react-select
.Select {
    $root: &;

    &__multi-value {
        color: white !important;
        margin-left: 8px !important;
        background-color: $red !important;

        &__label {
            padding: 6px 8px !important;
            color: white !important;
        }

        &__remove {
            &:hover {
                background-color: $red-60 !important;
                color: white !important;
            }
        }
    }

    &__control {
        border: 1px solid $gray-40 !important;
        appearance: none;
        background-image: url('#{$publicpath}img/icon-arrow-down.svg');
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: right 12px center;
        box-shadow: none !important;
        background-color: transparent !important;
        height: 44px !important;
        min-height: 44px;
        border-radius: 6px;

        &:active,
        &:hover {
            border: 1px solid $gray-40 !important;
            box-shadow: none !important;
        }

        &:focus {
            box-shadow: none !important;
            border: 1px solid $black !important;
        }
    }

    &__placeholder {
        padding-left: 32px !important;
        //color: $text !important;
    }

    &__input {
        // padding-left: 32px !important;
        font-weight: bold !important;
    }

    &__value-container {
        padding: 0 !important;
    }

    &__indicator-separator {
        background-color: transparent !important;
    }

    &__indicator {
        display: none !important;
    }

    &__single-value {
        color: $text !important;
        font-weight: bold !important;
        text-align: left;
        margin: 0 !important;
        padding-left: 32px !important;

        .FilterSelect--No-Icon & {
            padding-left: 16px !important;
        }

        #{$root}__control--menu-is-open & {
            color: $gray-40 !important;
        }
    }
}
