@import '../includes';

.theme-stockholmnorr {
    // Define variables for different colors first
    --primary: #{$stockholmnorr-primary};
    --primary-30: #{$stockholmnorr-secondary-40};
    --primary-50: #{$stockholmnorr-secondary};
    --primary-60: #{$stockholmnorr-secondary-60};

    --secondary: #{$stockholmnorr-secondary};
    --secondary-20: #{$stockholmnorr-secondary-20};

    // Define variables for locations
    --selection-bg: var(--primary-60);

    --link-color: var(--primary);

    --red-icon-color: var(--primary);

    --button-bg: var(--primary);
    --button-bg-hover: var(--primary-50);
    --button-bg-active: var(--primary-60);
    --button-color: white;

    --header-bg: white;
    --header-color: $text;
    --header-color-hover: var(--primary);
    --header-menu-item-bg: white;
    --header-menu-item-color-hover: var(--primary);
    --header-sub-color: $text;
    --header-sub-color-hover: var(--primary);
    --header-service-bg: white;
    --header-service-color: #{$gray-60};
    --header-service-color-hover: var(--primary);
    --header-service-border: #{$gray-15};

    --submenu-bg: var(--primary);
    --submenu-bg-active: var(--secondary);
    --submenu-bg-hover: var(--secondary);

    --mobile-menu-bg: var(--primary);
    --mobile-menu-list-bg: var(--primary);
    --mobile-menu-sub-bg: var(--primary);

    --footer-bg: var(--primary);
    --footer-color: white;
    --footer-link-color: white;
    --footer-border: white;

    --embed-message-bg: var(--primary);

    --quote-color: var(--primary);

    --checkbox-border: var(--primary);
    --checkbox-bg: var(--primary);

    --tab-color: var(--primary);
    --icon-color: var(--primary);

    --highlight-color: var(--primary);

    --input-select-bg: var(--primary);
    --input-hover-bg: var(--primary);

    --loader-color: var(--primary);

    --promo-bg: var(--secondary-20);
    --promo-color: black;

    --expandable-border: var(--primary);
    --expandable-toggle-active: var(--primary);
}
