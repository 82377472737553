@import '../includes';

.theme-gavleborg {
    // Define variables for different colors first
    --primary: #{$gavleborg-primary}; // Dark pink
    --primary-40: #{$gavleborg-primary-40}; // Pink
    --primary-80: #{$gavleborg-primary-80}; // Darker pink

    --secondary: #{$gavleborg-secondary}; // Yellow

    --tertiary: #{$gavleborg-tertiary}; // Teal

    --quaternary: #{$gavleborg-quaternary}; // Green

    // Define variables for locations
    --selection-bg: var(--primary-80);

    --link-color: var(--primary);

    --red-icon-color: var(--primary);

    --button-bg: var(--primary);
    --button-bg-hover: var(--primary-80);
    --button-bg-active: var(--primary-80);
    --button-color: white;

    --header-height: 94px;
    --header-height-lg: 90px;

    --header-bg: white;
    --header-color: $text;
    --header-color-hover: var(--primary);
    --header-menu-item-bg: white;
    --header-menu-item-color-hover: var(--primary);
    --header-sub-color: var(--primary);
    --header-sub-color-hover: var(--primary-80);
    --header-sub-border-color: black;
    --header-service-bg: white;
    --header-service-color: #{$gray-60};
    --header-service-color-hover: var(--primary);
    --header-service-border: #{$gray-15};

    --submenu-bg: var(--primary);
    --submenu-bg-active: var(--primary-40);
    --submenu-bg-hover: var(--primary-40);

    --mobile-menu-bg: var(--primary);
    --mobile-menu-list-bg: var(--primary);
    --mobile-menu-sub-bg: var(--primary);

    --footer-bg: var(--secondary);
    --footer-color: black;
    --footer-link-color: black;
    --footer-border: black;

    --embed-message-bg: var(--primary);

    --quote-color: var(--primary);

    --checkbox-border: var(--primary);
    --checkbox-bg: var(--primary);

    --tab-color: var(--primary);
    --icon-color: var(--primary);

    --highlight-color: var(--primary);

    --input-select-bg: var(--primary);
    --input-hover-bg: var(--primary);

    --loader-color: var(--primary);

    --promo-bg: var(--secondary);
    --promo-color: black;

    --expandable-border: var(--primary);
    --expandable-toggle-active: var(--primary);
}
