@import '../includes';

// Same as dalarna
.theme-norrbotten {
    // Define variables for different colors first
    --primary: #{$norrbotten-primary};
    --primary-20: #{$norrbotten-secondary-20};
    --primary-30: #{$norrbotten-secondary-40};
    --primary-60: #{$norrbotten-secondary-60};
    --primary-80: #{$norrbotten-secondary-80};

    --secondary: #{$norrbotten-secondary-80};

    // Define variables for locations
    --selection-bg: var(--primary-60);

    --link-color: var(--primary);

    --red-icon-color: var(--primary);

    --button-bg: var(--primary);
    --button-bg-hover: var(--primary-60);
    --button-bg-active: var(--primary-30);
    --button-color: white;

    --header-bg: white;
    --header-color: $text;
    --header-color-hover: var(--primary);
    --header-menu-item-bg: white;
    --header-menu-item-color-hover: var(--primary);
    --header-sub-color: $text;
    --header-sub-color-hover: var(--primary);
    --header-service-bg: white;
    --header-service-color: #{$gray-60};
    --header-service-color-hover: var(--primary);
    --header-service-border: #{$gray-15};

    --mobile-menu-bg: var(--primary);
    --mobile-menu-list-bg: var(--primary);
    --mobile-menu-sub-bg: var(--primary);

    --submenu-bg: var(--primary-80);
    --submenu-bg-active: var(--primary);
    --submenu-bg-hover: var(--primary);

    --footer-bg: var(--secondary);
    --footer-color: white;
    --footer-link-color: white;
    --footer-border: white;

    --embed-message-bg: var(--primary);

    --quote-color: var(--primary);

    --checkbox-border: var(--primary);
    --checkbox-bg: var(--primary);

    --tab-color: var(--primary);
    --icon-color: var(--primary);

    --highlight-color: var(--primary);

    --input-select-bg: var(--primary);
    --input-hover-bg: var(--primary);

    --loader-color: var(--primary);

    --promo-bg: var(--primary-20);
    --promo-color: black;

    --expandable-border: var(--primary);
    --expandable-toggle-active: var(--primary);
}
