#cookiescript_injected,
#cookiescript_injected_fsd {
    #cookiescript_wrapper {
        width: calc(100vw - 32px);
        max-width: 610px;
        padding: 16px;
        max-height: 90vh;
        overflow-x: hidden;
        font-family: $base-font;
        border-radius: 4px;

        @include media(sm) {
            padding: 24px;
        }
    }

    #cookiescript_header {
        font-size: 2rem;
        font-weight: 700;

        @include media(md) {
            font-size: 2.4rem;
        }
    }

    .cookiescript_checkbox_label {
        flex-wrap: nowrap;
    }

    .mdc-checkbox {
        flex: 0 0 22px !important;
        width: 22px !important;
        height: 22px !important;

        .mdc-checkbox {
            &__background {
                width: 22px;
                height: 22px;
                border-width: 1px;
                border-radius: 4px;

                &::before {
                    top: -12px !important;
                    left: -12px !important;
                    width: 44px !important;
                    height: 44px !important;
                }

                svg {
                    display: none;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    top: 1px;
                    left: 1px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-image: url('#{$publicpath}img/icon-checkmark.svg');
                    opacity: 0;
                    transition: opacity .3s ease-in-out;
                }
            }

            &__native-control {
                width: 44px !important;
                height: 44px !important;

                &:enabled:checked,
                &:enabled:indeterminate {
                    ~ .mdc-checkbox__background {
                        border-color: $checkbox-border;
                        background-color: $checkbox-bg;

                        &::after {
                            opacity: 1;
                        }
                    }
                }

                &:disabled:checked {
                    ~ .mdc-checkbox__background {
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    #cookiescript_buttons {

        #cookiescript_accept,
        #cookiescript_reject,
        #cookiescript_save {
            text-decoration: none;
            border-radius: 4px;
            color: white;
            cursor: pointer;
            font-family: proxima-nova, Arial;
            background-color: $button-bg;
            border: none;
            padding: 13px 18px 11px;
            font-size: 1.3rem;
            font-weight: 600;
            letter-spacing: 0.36px;
            text-transform: none;
            line-height: 1;

            &:hover {
                background: $button-bg-hover;

                @media (hover: none) {
                    background: $button-bg;
                }
            }

            &:active {
                background: $button-bg-active;
            }
        }

        #cookiescript_reject {
            color: $text;
            background-color: white;
            border: 1px solid $gray-20;

            &:hover {
                background-color: $gray-10;

                @media (hover: none) {
                    background-color: white;
                }
            }

            &:active {
                background-color: $gray-5;
            }
        }
    }

    #cookiescript_manage {
        &:hover {
            color: $tab-color;
        }
    }

    #cookiescript_maintabs {
        background: transparent;

        > div {
            color: $text;
            border: 1px solid $gray-10;
            border-bottom-width: 0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background: $gray-10;

            &:hover {
                color: $tab-color;
            }

            &.cookiescript_active {
                color: $tab-color;
                border-color: $gray-20;
                background: white;
            }
        }
    }

    #cookiescript_categories,
    #cookiescript_iab_type {
        > div {
            &:not(.cookiescript_active):hover {
                color: $tab-color;
            }
        }
    }

    #cookiescript_reportdate {
        display: none !important;
    }
}

#cookiescript_badge {
    background-color: white;

    .cookiescriptlogo {
        fill: $icon-color;
    }

    #cookiescript_badgetext {
        color: $text;
    }

    &:hover {
        background-color: $icon-color;

        .cookiescriptlogo {
            fill: white;
        }

        #cookiescript_badgetext {
            color: white;
        }

        @media (hover: none) {
            background-color: white;

            .cookiescriptlogo {
                fill: $icon-color;
            }

            #cookiescript_badgetext {
                color: $text;
            }
        }
    }

    .cookiescriptlogo {
        transition-duration: 500ms;
    }

    #cookiescript_badgetext {
        text-transform: none;
        font-size: 1.4rem;
        line-height: 2.8;
    }
}