.gm-style {
    .gm-ui-hover-effect {
        display: none !important; //Remove Close Icon from infoWindow
    }

    .gm-style-iw-tc {
        display: none; //Remove tip from infoWindow
    }

    // Style infoWindow
    .gm-style-iw-c {
        border-radius: 4px;
        width: 245px;
        padding: 13px 0 16px 15.5px;
    }
}
