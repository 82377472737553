@import '../includes';

.theme-vasterbotten {
    // Define variables for different colors first
    --primary: #{$vasterbotten-primary};
    --primary-20: #{$vasterbotten-tertiary-20};
    --primary-30: #{$vasterbotten-tertiary-40};
    --primary-60: #{$vasterbotten-tertiary};

    --secondary: #{$vasterbotten-secondary};
    --secondary-80: #{$vasterbotten-secondary-80};

    // Define variables for locations
    --selection-bg: var(--primary-60);

    --link-color: var(--primary);

    --red-icon-color: var(--primary);

    --button-bg: var(--primary);
    --button-bg-hover: var(--primary-60);
    --button-bg-active: var(--primary-60);
    --button-color: white;

    --header-bg: white;
    --header-color: $text;
    --header-color-hover: var(--primary);
    --header-menu-item-bg: white;
    --header-menu-item-color-hover: var(--primary);
    --header-sub-color: $text;
    --header-sub-color-hover: var(--primary);
    --header-service-bg: white;
    --header-service-color: #{$gray-60};
    --header-service-color-hover: var(--primary);
    --header-service-border: #{$gray-15};

    --submenu-bg: var(--secondary-80);
    --submenu-bg-active: var(--secondary);
    --submenu-bg-hover: var(--secondary);

    --mobile-menu-bg: var(--primary-20);
    --mobile-menu-border: #{rgba(black, .1)};
    --mobile-menu-list-bg: var(--primary-20);
    --mobile-menu-sub-bg: var(--primary-20);

    --footer-bg: var(--secondary-80);
    --footer-color: white;
    --footer-link-color: white;
    --footer-border: white;

    --embed-message-bg: var(--primary);

    --quote-color: var(--primary);

    --checkbox-border: var(--primary);
    --checkbox-bg: var(--primary);

    --tab-color: var(--primary);
    --icon-color: var(--primary);

    --highlight-color: var(--primary);

    --input-select-bg: var(--primary);
    --input-hover-bg: var(--primary);

    --loader-color: var(--primary);

    --promo-bg: var(--primary-20);
    --promo-color: black;

    --expandable-border: var(--primary);
    --expandable-toggle-active: var(--primary);
}
