@import '../includes';

.theme-sensus {
    // Define variables for different colors first
    --primary: #{$red};
    --primary-30: #{$red-30};
    --primary-60: #{$red-60};

    --secondary: #{$purple};

    --header-height: 68px;
    --header-height-lg: 75px;

    --mobile-menu-border: #{rgba(black, .1)};

    // Define variables for locations
}
