@import '../includes';

.theme-uppsala {
    // Define variables for different colors first
    --primary: #{$uppsala-primary};
    --primary-30: #{$uppsala-primary-40};
    --primary-60: #{$uppsala-primary-60};

    --secondary: #{$uppsala-secondary};
    --secondary-50: #{$uppsala-secondary-50};

    --quaternary-20: #{$uppsala-quaternary-20};

    // Define variables for locations
    --selection-bg: var(--primary-60);

    --link-color: var(--primary);

    --red-icon-color: var(--primary);

    --button-bg: var(--primary);
    --button-bg-hover: var(--primary-60);
    --button-bg-active: var(--primary-60);
    --button-color: white;

    --header-height: 94px;
    --header-height-lg: 100px;

    --header-bg: white;
    --header-color: $text;
    --header-color-hover: var(--primary);
    --header-menu-item-bg: white;
    --header-menu-item-color-hover: var(--primary);
    --header-sub-color: $text;
    --header-sub-color-hover: var(--primary);
    --header-service-bg: white;
    --header-service-color: #{$gray-60};
    --header-service-color-hover: var(--primary);
    --header-service-border: #{$gray-15};

    --submenu-bg: var(--primary);
    --submenu-bg-active: var(--secondary-50);
    --submenu-bg-hover: var(--secondary-50);

    --mobile-menu-bg: var(--primary);
    --mobile-menu-list-bg: var(--primary);
    --mobile-menu-sub-bg: var(--primary);

    --footer-bg: var(--primary);
    --footer-color: white;
    --footer-link-color: white;
    --footer-border: white;

    --embed-message-bg: var(--primary);

    --quote-color: var(--primary);

    --checkbox-border: var(--primary);
    --checkbox-bg: var(--primary);

    --tab-color: var(--primary);
    --icon-color: var(--primary);

    --highlight-color: var(--primary);

    --input-select-bg: var(--primary);
    --input-hover-bg: var(--primary);

    --loader-color: var(--primary);

    --promo-bg: var(--quaternary-20);
    --promo-color: black;

    --expandable-border: var(--primary);
    --expandable-toggle-active: var(--primary);
}
